var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Edit')))]):_vm._e(),(!_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Create')))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.l('Title')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'title',
                    {
                        rules: [
                            {
                                required: true,
                                message: _vm.l('ThisFieldIsRequired'),
                            }
                        ],
                    } ]),expression:"[\n                    'title',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: l('ThisFieldIsRequired'),\n                            }\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('Title')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('ImageUrl')}},[_c('a-popover',{attrs:{"title":_vm.l('Preview')}},[_c('template',{slot:"content"},[(_vm.entity.imageUrl)?_c('img',{attrs:{"src":_vm.entity.imageUrl,"alt":""}}):_vm._e(),(!_vm.entity.imageUrl)?_c('span',[_vm._v(_vm._s(_vm.l('NotSelected')))]):_vm._e()]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'imageUrl' ]),expression:"[\n                    'imageUrl',\n                    ]"}],attrs:{"placeholder":_vm.l('ImageUrlInputDesc')}},[_c('p',{staticClass:"pleaseSelect-text",attrs:{"slot":"addonAfter"},on:{"click":function($event){return _vm.chooseFile('imageUrl')}},slot:"addonAfter"},[_vm._v(_vm._s(_vm.l('PleaseSelect')))])])],2)],1),_c('a-form-item',{attrs:{"label":_vm.l('ThumbImgUrl')}},[_c('a-popover',{attrs:{"title":_vm.l('Preview')}},[_c('template',{slot:"content"},[(_vm.entity.thumbImgUrl)?_c('img',{attrs:{"src":_vm.entity.thumbImgUrl,"alt":""}}):_vm._e(),(!_vm.entity.thumbImgUrl)?_c('span',[_vm._v(_vm._s(_vm.l('NotSelected')))]):_vm._e()]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'thumbImgUrl' ]),expression:"[\n                    'thumbImgUrl',\n                    ]"}],attrs:{"placeholder":_vm.l('ThumbImgUrlInputDesc')}},[_c('p',{staticClass:"pleaseSelect-text",attrs:{"slot":"addonAfter"},on:{"click":function($event){return _vm.chooseFile('thumbImgUrl')}},slot:"addonAfter"},[_vm._v(_vm._s(_vm.l('PleaseSelect')))])])],2)],1),_c('a-form-item',{attrs:{"label":_vm.l('Description')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'description',
                    {
                        rules: [
                            {
                                required: true,
                                message: _vm.l('ThisFieldIsRequired'),
                            }
                        ],
                    } ]),expression:"[\n                    'description',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: l('ThisFieldIsRequired'),\n                            }\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('DescriptionInputDesc'),"auto-size":{ minRows: 3, maxRows: 5 },"maxLength":"3000"}})],1),_c('a-form-item',{attrs:{"label":_vm.l('BannerAdUrl')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'url',
                    {
                        rules: [
                            {
                                required: true,
                                message: _vm.l('ThisFieldIsRequired'),
                            }
                        ],
                    } ]),expression:"[\n                    'url',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: l('ThisFieldIsRequired'),\n                            }\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('BannerAdUrlInputDesc')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('Weight')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'weight',
                    {
                        rules: [
                        ],
                    } ]),expression:"[\n                    'weight',\n                    {\n                        rules: [\n                        ],\n                    },\n                    ]"}],attrs:{"min":0,"max":1000000,"step":1}})],1),_c('a-form-item',{attrs:{"label":_vm.l('Price')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'price',
                    {
                        rules: [
                        ],
                    } ]),expression:"[\n                    'price',\n                    {\n                        rules: [\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('BannerAdPriceInputDesc')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('BannerAdTypes')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'types',
                    {
                        rules: [
                            {
                                required: true,
                                message: _vm.l('ThisFieldIsRequired'),
                            }
                        ],
                    } ]),expression:"[\n                    'types',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: l('ThisFieldIsRequired'),\n                            }\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('BannerAdTypesInputDesc')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('ViewCount')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'viewCount',
                    {
                        rules: [
                        ],
                    } ]),expression:"[\n                    'viewCount',\n                    {\n                        rules: [\n                        ],\n                    },\n                    ]"}],attrs:{"min":0,"max":1000000,"step":1}})],1),_c('a-form-item',{staticClass:"btn--container"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }